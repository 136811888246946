.attendancePage {
    padding: 16px;
}

.table-responsive {
    overflow-x: auto;
}

.cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    margin-top: 16px;
}

.card {
    background-color: #ffffff;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-bottom: 20px;
    overflow: hidden;
}

/* .card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15), 0px 16px 32px rgba(0, 0, 0, 0.25);
} */

.card-header {
    background-image: linear-gradient(135deg, #38b6ff, #385eff);
    color: #ffffff;
    padding: 10px;
    font-weight: bold;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
}

.card-body {
    padding: 15px;
}

.name,
.attendance-status,
.attendance-time {
    margin: 4px 0;
}

.checkbox-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
}

.form-check {
    display: flex;
    align-items: center;
}


.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.pagination-button {
    color: black;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2),
        -4px -4px 8px rgba(255, 255, 255, 0.5);
    transition: transform 0.2s, box-shadow 0.2s, background 0.3s;
}

.pagination-button:hover {
    transform: translateY(-3px);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4),
        -2px -2px 10px rgba(255, 255, 255, 0.6);
    /* Darker shadow on hover */
}

.pagination-button:disabled {
    background: #cfcfcf;
    color: #a1a1a1;
    cursor: not-allowed;
    box-shadow: none;
}

.pagination-info {
    font-size: 18px;
    margin: 0 10px;
    color: #333;
}

.move-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s;
    z-index: 1000;
}

.move-to-top:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .cards-container {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .form-check {
        gap: 10px;
    }

    .card {
        padding: 12px;
    }

    .move-to-top {
        opacity: 1;
    }
   
}