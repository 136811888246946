.successCard{
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    color: rgb(86, 85, 85);
}
.successImg{
height: 15rem;    
}