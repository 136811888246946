/* General container to hold the two-column layout */
.dashboardText {
  /* margin-top: 60px !important; */
  display: flex;
  justify-content: flex-start;
}

.dashboard-container {
  /* display: flex; */
  justify-content: flex-start;
  /* padding: 15px 0; */
  gap: 20px;
  margin-bottom: 15px;
}

/* First column styling to have all boxes in one row */
.first-column {
  display: flex;
  gap: 20px;
  flex: 3;
}

/* Each box in the first column */
.darkbluebox,
.bluebox,
.yellowbox,
.purplebox {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 28px;
  /* width: 230px; */
  /* Adjust width for each box */
  height: 170px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 400px !important;
}

.bluebox,
.yellowbox,
.purplebox {
  background-color: #93befe;
  background-image: linear-gradient(135deg, #93befe, #628fda);
}

.darkbluebox p,
.bluebox p,
.yellowbox p,
.purplebox p {
  margin: 0;
  font-weight: bold;
  font-size: 22px;
  color: #fff !important;
  font-family: 'Poppins';
}

/* Specific colors for yellow and purple and darkblue boxes */
.yellowbox {
  background-color: #eecb4b;
  background-image: linear-gradient(135deg, #eecb4b, #e9b320);
}

.darkbluebox {
  background-color: #004aad;
}

.purplebox {
  background-color: #c770dc;
  background-image: linear-gradient(135deg, #c770dc, #6853d2);
}

.card-clickable {
  cursor: pointer;
  flex: 1 1 calc(33.33% - 10px);
  /* Adjust card width for 3 per row with reduced gap */
}

/* Image styling inside the box */
.small-image {
  position: absolute;
  bottom: 10px;
  right: 15px;
  width: 60px;
  height: 60px;
}


/* Second column styling */
.second-column {
  flex: 1;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Follow-up text styling */
.follow-up-text {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}
/* 
.adhikariAttendanceCard{
  padding:10px ;
  border: none !important;
  height: 10.5rem !important;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  gap: 10px;
}
.adhikariAttendanceFields{
display: flex;
justify-content: space-between;
align-items:center;
font-weight: 500;
}
.attendanceCount{
  background-color: rgb(229, 198, 20);
  color: white;
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 600;
} */
.attendanceCard{
  /* background: #cedff6 !important; */
}
/*  */

/* Table container */
.tablecontainer {
  margin-top: 20px;
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  /* Add box shadow to the table */
  border-radius: 10px !important;
  /* Rounded corners */
  padding: 15px;
}

/* Responsive table styling */
.table-responsive {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

table thead th {
  background-color: #3a69ff !important;
  /* Custom background color */
  font-size: 16px !important;
  /* Custom font size */
  color: #fff !important;
  /* Custom text color */
  text-align: center !important;
  /* Center text in header cells */
  padding: 5px !important;
  /* Padding for better spacing */
  border: 2px solid #8a8a8a;
}

table tbody td {
  font-size: 14px;
  /* Slightly smaller font for body rows */
  text-align: center;
  /* Center text in body cells */
  padding: 10px;
  font-family: 'Poppins';
  color: #252525;
  border: 2px solid #8a8a8a;
}

table tbody tr,
table thead tr {
  border: 2px solid #8a8a8a;
}

table tbody .table-info {
  --bs-table-bg: #fff !important;
  /* Light background color for totals row */
  font-weight: bold;
  /* Make totals row bold */
}

.table-responsive th,
.table-responsive td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.table-responsive th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-responsive td {
  font-size: 14px;
}

/* Floating  Birthday logo */
.floating-logo {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 80px;
  height: 80px;
  cursor: pointer;
  z-index: 1000;
}

.floating-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Popup styling */
.birthday-popup {
  position: fixed;
  bottom: 110px;
  /* Position above the floating icon */
  right: 20px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  z-index: 2000;
  width: 380px;
  /* Adjusted width to fit entries */
}

/* Box for each birthday entry */
.birthday-entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Box shadow for subtle effect */
  margin-bottom: 10px;
}

/* Profile picture inside the box */
.popup-profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Name and date container */
.popup-info {
  flex: 1;
}

/* Name styling */
.popup-name {
  font-size: 16px;
  margin: 0;
}

/* Date styling */
.popup-date {
  font-size: 12px;
  color: gray;
  margin: 0;
  /* To keep it close to the name */
}

.send-wishes-btn {
  background-color: #eecb4b;
  background-image: linear-gradient(135deg, #eecb4b, #e9b320);
  color: #1f1f1f;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .dashboard-container {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    justify-content: center;
    height: 18% !important;
  }

  .first-column {
    display: flex;
    gap: 10px;
    width: 97%;
  }

  .darkbluebox,
  .bluebox,
  .yellowbox,
  .purplebox {
    width: 100%;
    /* Full width in mobile */
    height: auto;
    flex-direction: column;
    /* Stack icon and text vertically */
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center text and icon vertically */
  }

  .small-image {
    width: 65px;
    /* Adjust image size for mobile */
    height: 65px;
    margin-bottom: 10px;
    /* Space between icon and text */
    position: unset !important;
  }

  .darkbluebox p,
  .bluebox p,
  .yellowbox p,
  .purplebox p {
    font-size: 0.6rem !important;
    /* Smaller font size for mobile */
    text-align: center;
    /* Center text in mobile */
    position: absolute;
    top: 76% !important;
  }

  .floating-logo {
    width: 60px;
    /* Smaller size for mobile */
    height: 60px;
    bottom: 15px !important;
    /* Adjust position slightly for mobile */
    right: 15px;
  }

  .birthday-popup {
    width: 350px;
    bottom: 100px;
    right: 10px;
  }

  .popup-profile-pic {
    width: 40px;
    height: 40px;
  }

  .popup-name {
    font-size: 1rem;
  }

  .popup-date {
    font-size: 0.85rem;
  }

  .send-wishes-btn {
    font-size: 14px;
    padding: 5px 10px;
  }
}

.Adhikari.row-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* Ensures wrapping on small screens */
  gap: 20px;
  /* Add spacing between the cards and follow-up */
}

.Adhikari .first-column {
  display: flex;
  gap: 20px;
}

.Adhikari .card-clickable {
  width: 200px;
  /* Set a fixed width for cards */
  height: 170px;
  /* Set a fixed height for cards */
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.Adhikari .card-clickable img {
  width: 50px;
  height: 50px;
}

.Adhikari .bluebox p,
.Adhikari .purplebox p,
.Adhikari .yellowbox p {
  font-size: 24px !important;
  padding: 8px;
}

@media (max-width: 768px) {
  .Adhikari .row-container {
    flex-direction: column;
    /* Stack cards and follow-up vertically */
  }

  .Adhikari .first-column {
    flex-direction: row;
    /* Stack cards in a column */
    margin-bottom: 20px;
    width: 100%;
  }

  .Adhikari .bluebox,
  .Adhikari .purplebox,
  .Adhikari .yellowbox {
    justify-content: unset;
  }

  .Adhikari .bluebox p,
  .Adhikari .purplebox p,
  .Adhikari .yellowbox p {
    top: 62px !important;
    font-size: 10px !important;
    padding: 3px;
  }

  .Adhikari .card-clickable {
    height: 111px;
  }
}

.sewadalCountSec{
  margin-top: 50px;
}