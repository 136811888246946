.satsang-container {

    margin-top: 60px;
    background-color: #fff;
    padding: 28px;
    border-radius: 12px;
    margin: 60px 22px 0 22px;


    h3 {
        font-size: 20px;
    }
}