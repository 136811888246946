.routes {
  color: #8a8a8a ;
  font-size: 15px ;
}
.SewadalForms{
  margin-bottom: 10px;  
  margin-left: 0.8% !important;
}
  
label{
  display: flex !important;
  gap: 5px !important;
}

input[disabled] {
  background-color: #e0dfdf !important;
}

.addRow, .deleteRow{
  background: none;
  border: none;
  cursor: pointer;
}
