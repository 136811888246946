/* Hamburger Menu Button Styles */
.hamburger-menu {
  width: 36px; /* Width of the hamburger button */
  height: 36px; /* Height of the hamburger button */
  z-index: 11000; /* Ensure it's above other content */
  background: none; /* No background */
  border: none !important; /* Remove any border */
  cursor: pointer; /* Pointer cursor */
  background-color: transparent !important;
}

/* Make sure the icon inside the button is appropriately sized */
.hamburger-menu img, 
.hamburger-menu svg {
  margin-top: 8% !important;
  width: 186% !important;
  height: 100% !important;
  pointer-events: none; /* Prevent interaction with the icon itself */
  color: #8a8a8a;
  border: none; /* Remove border */
}

.dropdown-toggle::after {
  margin-left: 0.7em !important;
}
