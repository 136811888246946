.attendanceCount {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 1rem;
    background-color: #215393 !important;
  }
  
  .adhikariAttendanceSingleCard{
      background: #efeeee !important;
    padding:10px ;
    border-radius: 7px;
    cursor: pointer;
    font-weight: 600;
    color: #555454;
  }
  
  .adhikariAttendanceText{
  width:13rem;
  }