html,
body {
  height: 100%;
  margin: 0;
}
.alert-wrap {
  position: absolute;
  top : 30px;
  width : 90%;
}

.password-toggle-icon {
  position: absolute;
  right: 20px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7f7b7b; /* Optional: Adjust the color */
}

.login-container {
  display: flex !important;
  /* Use flexbox */
  height: 100vh !important;
  /* Full viewport height */

  /* Section 1: Image Section */
  .image-section {
    flex: 1;
    /* Allow this section to grow and fill available space */
    background-color: white;
    /* Set background color to white */
    display: flex;
    /* Use flexbox to center content */
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
  }

  .image-section .img-fluid {
    max-width: 70%;
    /* Ensure the image is responsive */
    max-height: 60%;
    /* Ensure the image doesn't overflow */
  }

  /* Hide image section on mobile devices */
  @media (max-width: 767px) {
    .image-section {
      display: none;
      /* Hide on mobile devices */
    }

    .login-container {
      flex-direction: column;
      /* Stack vertically on mobile devices */
    }

    .login-container {
      flex-direction: column;
      /* Stack vertically on mobile devices */
    }

    .form-section {
      height: 100%;
      /* Take full height on mobile devices */
    }
  }

  .form-section {
    flex: 1;
    /* Takes up half of the container's width */
    font-size: 16px !important;
    font-family: 'Poppins', sans-serif !important;
    Background-image: linear-gradient(135deg, #38b6ff, #385eff);
    /* Blue background */
    color: #fff !important;
    display: flex;
    align-items: center;
    /* Vertically center items */
    justify-content: center;
    /* Horizontally center items */
    height: 100%;
    padding: 2rem;
  }

  .form-content {
    padding: 2rem;
    width: 100%;
    height: 80;
    margin: 0 auto;
    /* Center the form horizontally */
  }

  .logo-container {
    display: flex;
    justify-content: center;
    /* Center logo horizontally */
    width: 100%;
    /* Take full width of the container */
  }

  .logo {
    height: auto;
    /* Maintain aspect ratio */
    width: 24%;
  }

  h1 {
    font-family: 'Poppins' !important;
    font-size: 2rem !important;
    margin-bottom: 0.5rem !important;
    color: #fff !important;
    /* Heading color */
    text-align: center;
    font-weight: 700 !important;
  }

  h3 {
    font-family: 'Poppins' !important;
    font-size: 20px !important;
    margin-bottom: 1.5rem !important;
    color: #fff !important;
    /* Subheading color */
    font-weight: 500 !important;
    text-align: center;
  }

  .form-control {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    /* Regular weight for input fields */
    padding: 0.75rem 1.25rem;
    /* Padding for input fields */
    background-color: #fff !important;
    border-radius: 6px !important;

  }

  .KeepMeLoggedIn {
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif !important;
    color: #fff !important;
    padding-top: 1.5%;
  }

  .forgot-password-link {
    color: #e2e2e2;
    /* Light color for the link */
  }


  .forgot-password-link {
    color: #fff !important;
    /* Light color for the link */
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px !important;

  }

  .forgot-password-link:hover {
    text-decoration: underline;
  }

  .login-button {
    background-color: #eecb4b !important;
    background-image: linear-gradient(135deg, #eecb4b, #e9b320) !important;
    color: #1f1f1f !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 400 !important;
  }
}