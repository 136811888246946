/* Toaster.css */
.toast-container {
    position: fixed;
    bottom: 20px !important;
    right: 10px !important;
    z-index: 1050; /* Ensure it's above other content */
  }
  
  .toast {
    opacity: 0.9;
    transition: opacity 0.3s ease-in-out;
  }
  
  .toast.show {
    opacity: 1;
  }
  