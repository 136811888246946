/* SewadalCount.css */

.sewadlCountSec .card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    margin-bottom: 20px; /* Add margin between cards */
    padding: 2rem; /* Increase padding inside cards for better spacing */
  }
  
  .sewadlCountSec .card:hover {
    transform: scale(1.05); /* Add slight hover effect */
  }
  
  .sewadlCountSec h3 {
    font-size: 2.5rem; /* Make headings bigger for better readability */
  }
  
  .sewadlCountSec p {
    font-size: 1.3rem; /* Increase font size for paragraphs */
    color: #6c757d; /* Bootstrap muted text color */
  }
  
  .sewadlCountSec .row {
    margin-bottom: 3rem; /* Add more space between rows */
  }

  .sewadalCountCard1 ,.sewadalCountCard2{
    background: rgb(255, 255, 255);
    margin-top: none;
    margin: 1px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .sewadalCountCard1 p{
    font-size: 15px;
  }
  .sewadalCountCard1 h3{
    /* margin-top: 40px; */
  }
  .sewadalCountCard2{
    border-radius: 10px;
    padding-left: 60px;
  }
  .innerCountCard{
    gap: 50px; 
  }

  .sewadalCounter{
    display: flex;
    /* justify-content: center; */
    align-items: end;
    gap: 30px;
  }

  .icon{
    background: rgb(49, 234, 181);
    height: 4rem;
    width: 4rem !important;
    padding: 15px;
    border-radius: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }
  .nav-link {
    color: #888;
  }
  .nav-link.active{
    color: #1f1f1f;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }