.routes {
  color: #8a8a8a ;
  font-size: 15px ;
}
legend{
  margin-bottom: 10px;  
}
  .mandatory-text {
    color: #8a8a8a;
    font-size: 12px;
  }
  
label{
  display: flex;
  gap: 5px;
}
.astric{
    color: red;
  }
  
.byDefaultInput{
    background-color: #e0dfdf !important;
  }
