/* Header Styles */
.full-width-header {
  width: calc(
    100% - 200px
  ); /* Adjust width based on sidebar width for desktop */
  background-color: #ffffff; /* Set background color */
  border-bottom: 1px solid #ddd; /* Light grey border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better separation */
  z-index: 1001; /* Ensure header is above other content */
  padding: 20px;
  position: fixed; /* Keep header fixed */
  top: 0;
  left: 200px; /* Adjust based on sidebar width */
  display: flex;
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Vertically center items */
  transition: width 0.3s ease, left 0.3s ease; /* Smooth transition */
  box-sizing: border-box; /* Include padding in the width calculation */
  overflow: hidden; /* Prevent overflow */
  height: auto;
}

/* Adjust header width when sidebar is closed */
.full-width-header.shifted {
  width: 100%;
  left: 0;
}

/* Search Bar Styles */
.search-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 1rem; /* Space between search bar and buttons */
}

.search-bar {
  flex: 1; /* Make search input take up available space */
  width: 70%; /* Adjust width considering the hamburger menu space */
  height: 35px !important; /* Increased height for the search bar */
  padding: 0 2rem; /* Space for icons */
  font-size: 16px; /* Adjust font size for readability */
}

/* Placeholder Styles for Search Bar */
.search-bar::placeholder {
  color: #8a8a8a; /* Placeholder text color */
  font-size: 14px; /* Placeholder text size */
  font-family: "Poppins", sans-serif; /* Placeholder text font */
}

.web-search-icon,
.mic-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.web-search-icon {
  left: 0.5rem; /* Space from left edge */
}

.mic-icon {
  right: 0.5rem; /* Space from right edge */
}

.search-bar-container .web-search-icon,
.search-bar-container .mic-icon {
  font-size: 1rem; /* Adjust size as needed */
  color: #8a8a8a; /* Icon color */
}

/* Header Button Toolbar */
.btn-toolbar {
  display: flex;
  align-items: center;
  margin-left: auto; /* Push the toolbar to the right */
  gap: 0.5rem; /* Space between buttons */
}

.btn-group {
  display: flex;
}

/* Ensure the button styles for Comment, Notification, and Profile are updated */
.btn-outline-info {
  border: none; /* Remove border */
  background-color: transparent; /* Remove background color */
  box-shadow: none; /* Remove shadow */
  color: #8a8a8a; /* Default text/icon color */
}

.btn-outline-info:hover,
.btn-outline-info:focus {
  background-color: transparent !important; /* Ensure no background color on hover/focus */
  color: #8a8a8a; /* Ensure icon color remains the same */
  box-shadow: none; /* Ensure no shadow on hover/focus */
  border-color: transparent !important;
}

/* If you want to apply styles specifically to these buttons */
.btn-toolbar .btn-outline-info {
  border-color: transparent; /* Ensure no border color */
  background-color: transparent; /* Ensure no background color */
  color: #8a8a8a; /* Set the color of the icons */
}

.btn-outline-info svg {
  color: #8a8a8a; /* Set icon color */
  font-size: 1rem; /* Adjust size as needed */
}

/* Add Attendance Button Styles */
.btn-add-attendance {
  background-color: #eecb4b; /* Solid background color */
  background-image: linear-gradient(
    135deg,
    #eecb4b,
    #e9b320
  ); /* Gradient background */
  color: #1f1f1f; /* Text color */
  font-size: 14px; /* Font size for desktop */
  font-family: "Poppins", sans-serif; /* Font family */
  border: none; /* Remove border */
  border-radius: 50%; /* Circular button */
  padding: 0.5rem 1rem; /* Padding for button size */
  margin-right: 0.5rem; /* Space between this and the next button */
  display: flex; /* Flex for centering content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  transition: all 0.3s ease; /* Smooth transition for hover and size changes */
  border-radius: 5px !important;
}

.btn-add-attendance:hover {
  background-image: linear-gradient(
    135deg,
    #e9b320,
    #eecb4b
  ); /* Reversed gradient on hover */
}

.btn-add-attendance:focus {
  box-shadow: none; /* Remove focus outline */
  outline: none; /* Remove default focus outline */
}

.btn-add-attendance svg {
  color: #1f1f1f; /* Icon color matching text */
}

/* Ensure main content does not overlap the fixed header */
.content {
  padding-top: 80px; /* Adjust this value based on header height */
}
.profile-dropdown-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.userIcon {
  width: 80px;
  height: 80px;
  font-size: 50px;
  color: #8a8a8a;
  border: 1px solid #8a8a8a;
  border-radius: 100px;
}
.profile-dropdown-wrap .profile-login-name {
  font-size: 16px;
  color: #252525;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.signout-btn {
  font-size: 16px;
  color: #215393;
  background-color: #f3efef;
}

.profile-login-email {
  color: #160d75;
  font-size: 14px;
}
/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .full-width-header {
    width: 100%;
    left: 0; /* Align header with the viewport */
    padding: 10px 10px; /* Adjust padding for mobile view */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* Ensure elements inside the header wrap */
  .full-width-header > * {
    text-align: center; /* Center align elements */
  }

  .search-bar-container {
    width: 100%; /* Make search bar full width */
    margin-left: 0; /* Remove extra margin on mobile */
  }
  .search-bar {
    height: 36px !important;
    width: calc(100% - 60px);
  }

  .btn-toolbar {
    width: 100%; /* Make toolbar full width */
    gap: 1rem; /* Add space between buttons */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end !important;
  }
  .btn-add-attendance {
    display: none !important;
  }
}

/* @media (max-width: 480px) {
    .btn-add-attendance {
      font-size: 10px; 
      padding: 0.3rem 0.6rem; 
      border-radius: 30px; 
    }
  } */

.dropdown-toggle::after {
  margin-left: 0.7em !important;
}
.profileMenu {
  z-index: 10000 !important;
  margin-left: 70% !important;
  margin-top: 3% !important;
  font-size: 12px !important;
  width: -17% !important;
  text-align: center !important;
  padding: 0px !important;
}

/* Mobile logo styles */
.mobile-logo {
  margin-top: 0% !important;
  width: 100px; /* Adjust width as needed */
  height: auto;
  margin-left: 10px;
}

/* Hide logo on web view */
@media (min-width: 768px) {
  .mobile-logo {
    display: none;
  }
  /* Hide search bar on desktop view */
  .mobile .search-bar-mobile {
    display: none;
  }

  .mobile-search-icon-btn {
    display: none; /* Hide search icon button on desktop */
  }
}
/* Search Icon Button for Mobile */
.mobile-search-icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #8a8a8a !important;
}

.mobile-search-icon {
  font-size: 1.5rem;
  color: #8a8a8a !important;
}

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.6
  ); /* Grayed-out effect with transparency */
  z-index: 1050; /* Ensure it covers other elements */
}

/* Search Bar for Mobile */
.mobile .search-bar-mobile {
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

/* Close Button inside Mobile Search Bar */
.mobile .close-search-icon {
  font-size: 1.5rem;
  margin-left: 10px;
  cursor: pointer;
  color: #ffffff;
  z-index: 10000;
}

/* Search Bar Input */
.mobile .search-bar {
  width: 100%;
  padding: 8px;
  outline: none;
  font-size: 1rem;
  z-index: 10000;
}

.dropdown-menu.show {
  position: fixed;
  right: 20px;
  top: 6px;
  width: 280px;
  height: 185px;
}

@media (max-width: 768px) {
  .dropdown-menu.show {
    top: 40px;
  }
}
