/* Sidebar Styles */
#sidebar {
  padding : 0 12px;
  background-color: #ffffff !important;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  /* Full viewport height */
  z-index: 1000;
  position: fixed;
  /* Keep sidebar fixed on the left */
  top: 0;
  left: 0;
  width: 200px;
  /* Default width of the sidebar */
  transition: transform 0.3s ease;
  /* Smooth transition for sliding effect */
  overflow-y: auto;
  /* Scroll within the sidebar if content overflows */
}

.btn-link {
  width: 100%;
  text-align: justify !important;
}

.sub-item-link {
  margin-left: 12px;
}

/* Ensure the sidebar content grows and takes available space */
.sidebar-sticky {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#sidebar.closed {
  transform: translateX(-1000px);
  /* Hide sidebar off-screen */
}

.dashboardlogo {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dashboardlogo img {
  max-width: 100%;
  height: auto;
}

#sidebar .hamburger-menu {
  margin-top: 1% !important;
}

#sidebar .nav-link {
  color: #8a8a8a;
  font-size: 14px;
  font-family: 'Poppins', sans-serif !important;
}

#sidebar .nav-link.active {
  color: #ffffff;
  background-image: linear-gradient(135deg, #38b6ff, #385eff);
  /* Blue background */
  border-radius: 4px;
  /* Adjusted for a smoother look */
}

#sidebar .nav-link:hover {
  background-color: #e9ecef;
}

#sidebar .dropdown-menu {
  background-color: #ffffff;
  /* Ensure dropdowns have a white background */
}

#sidebar .dropdown-item {
  color: #8a8a8a;
}

#sidebar .dropdown-item:hover {
  background-color: #f1f1f1;
}

#sidebar::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

#sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
  border-radius: 10px;
}

#sidebar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Rounded edges for the scrollbar handle */
  border: 2px solid #f1f1f1;
  /* Optional border for visual effect */
}

#sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker color when hovering over the scrollbar */
}

/* Scrollbar for Firefox */
#sidebar {
  scrollbar-width: thin;
  /* Makes the scrollbar thinner */
  scrollbar-color: #b8b6b6  #f1f1f1;
  /* Handle color #888 and track color #f1f1f1 */
}

/* Optional: Change the hover effect in Firefox */
#sidebar:hover {
  scrollbar-color: #9e9a9a  #f1f1f1;
  /* Darker handle when hovering */
}

/* Adding padding to ensure smooth scrolling experience */


/* Responsive Design for Mobile */
@media (max-width: 768px) {
  #sidebar {
    width: 50%;
    /* Full width for mobile view */
    height: -webkit-fill-available;
    /* Full viewport height */
    transform: translateX(-100%);
    /* Hide off-screen by default */
    z-index: 10000;
    transition: transform 0.3s ease;
    position: fixed;
    /* Keep sidebar fixed on the left */
  }

  #sidebar.open {
    transform: translateX(0);
    /* Show sidebar when open */
  }

  .dashboardlogo {
    display: none !important;
  }

  .dashboardlogo img {
    display: none !important;
  }

  .snm-nav-menu {
    margin-top: 15% !important;
  }
}