.paginator {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
  font-size: 0.9rem;
}

.items-per-page {
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  font-size: 0.9rem;
}

.pagination-controls {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 4px;
}

.icon-button:disabled {
  color: #ccc;
  /* cursor: not-allowed; */
}

.icon-button:focus {
  outline: none;
}

.icon-button:hover {
  color: #1e88fa;
}

.pagination-controls span {
  font-size: 0.9rem;
  color: #333;
}
