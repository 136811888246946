/* src/index.css */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #f4f4f4;
}

.container {
  max-width: 600px;
}

/* Container for the SNM Page */
.snmpage-container {
  /* display: flex; */
  height: 100vh;
  /* Full viewport height */
}

/* Content area should take up remaining space */
.content-area {
  flex: 1;
  margin-left: 0;
  /* Start without the sidebar in mobile view */
  transition: margin-left 0.3s ease;
  /* Smooth transition for sidebar toggle */
  padding: 20px !important;
  padding-top: 4% !important;
}

/* If sidebar is open, shift the content area */
.snmpage-container.with-sidebar .content-area {
  margin-left: 205px;
  padding-top: 106px !important;
  /* Adjust to the width of the sidebar */
}

/* Responsive: For smaller screens */
@media (max-width: 768px) {
  .snmpage-container.with-sidebar .content-area {
    margin-left: 0;
    /* Sidebar collapses, no margin needed */
  }

  .snmpage-container {
    flex-direction: column;
    /* Stack header and content vertically */
  }

  .content-area {
    margin-left: 0;
    /* No left margin for mobile */
    padding-top: 60px !important;
    /* Padding to avoid overlap with header */
  }
}


/* Table container */
.tablecontainer {
  margin-top: 20px;
  width: 100%;
  background-color: #fff !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1) !important;
  /* Add box shadow to the table */
  border-radius: 10px !important;
  /* Rounded corners */
  padding: 15px;
}

/* Responsive table styling */
.table-responsive {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

table thead th {
  background-color: #3a69ff !important;
  /* Custom background color */
  font-size: 16px !important;
  /* Custom font size */
  color: #fff !important;
  /* Custom text color */
  text-align: center !important;
  /* Center text in header cells */
  padding: 5px !important;
  /* Padding for better spacing */
  border: 2px solid #8a8a8a;
}

table tbody td {
  font-size: 14px;
  /* Slightly smaller font for body rows */
  text-align: center;
  /* Center text in body cells */
  padding: 10px;
  font-family: 'Poppins';
  color: #252525;
  border: 2px solid #8a8a8a;
}

table tbody tr,
table thead tr {
  border: 2px solid #8a8a8a;
}

table tbody .table-info {
  --bs-table-bg: #fff !important;
  /* Light background color for totals row */
  font-weight: bold;
  /* Make totals row bold */
}

.table-responsive th,
.table-responsive td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  max-width: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 8px 13px;
}

.table-responsive th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-responsive td {
  font-size: 14px;
}

.d-flex {
  display: flex;
}

.j-center {
  justify-content: center;
}

input[type='checkbox'] {
  cursor: pointer;
}

.j-end {
  justify-content: end;
}

.help-text {
  color: #8a8a8a;
  font-size: 14px;
}

.align-center {
  align-items: center;
}

.btn-secondary {
  background-color: #eecb4b;
  /* Solid background color */
  background-image: linear-gradient(135deg, #eecb4b, #e9b320);
  /* Gradient background */
  color: #1f1f1f;
  /* Text color */
  font-size: 14px;
  /* Font size for desktop */
  font-family: 'Poppins', sans-serif;
  /* Font family */
  border: none;
  /* Remove border */
  border-radius: 50%;
  /* Circular button */
  padding: 0.5rem 1rem;
  /* Padding for button size */
  margin-right: 0.5rem;
  /* Space between this and the next button */
  display: flex;
  /* Flex for centering content */
  align-items: center;
  /* Center content vertically */
  justify-content: center;
  /* Center content horizontally */
  transition: all 0.3s ease;
  /* Smooth transition for hover and size changes */
  border-radius: 5px !important;
  font-size: 16px;
  font-weight: 500;
}

.btn-secondary:hover {
  background-image: linear-gradient(135deg, #e9b320, #eecb4b);
  /* Reversed gradient on hover */
  color: #1f1f1f;
  /* Text color */
}

.btn-secondary:focus {
  box-shadow: none;
  /* Remove focus outline */
  outline: none;
  /* Remove default focus outline */
}


.icon-btn {
  cursor: pointer;
}

.deleted-row {
  td {
    color: red
  }
}

.icon-button {
  background-color: transparent;
  border: none;

  &:hover {
    background: none !important;
  }
}

.error-help-text {
  color: #f41414;
  font-size: 13px;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Darken the background */
  z-index: 9999; /* Ensure the overlay is on top of everything */
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 3rem;
  height: 3rem;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #318ae9; /* Spinner color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.fade-content {
  opacity: 0.8; /* Fade the entire page content */
  pointer-events: none; /* Prevent interaction with page content */
}
