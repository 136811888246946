.breadcrumb-link {
    text-decoration: none !important; /* Remove underline */
    color: gray !important; /* Set link color to gray */
    font-size: 12px !important; /* Set font size */
  }
  
  .breadcrumb-link:hover,
  .breadcrumb-link:focus,
  .breadcrumb-link:active {
    text-decoration: none !important; /* Ensure no underline on hover, focus, or active */
    color: gray !important; /* Keep the color gray on hover, focus, or active */
  }
  
  .breadcrumb-link-last {
    font-weight: bold !important; /* Make the last breadcrumb bold */
    color: gray !important; /* Ensure last breadcrumb color is gray */
    font-size: 12px !important; /* Ensure font size is 12px for the last breadcrumb */
    text-decoration: none !important; /* Remove underline for the last breadcrumb */
  }
  .breadcrumb .breadcrumb-link a {
    color: #0260c1;
    text-decoration: none;
  }
  