.routes {
    color: #8a8a8a;
    font-size: 15px;
}

label {
    display: flex !important;
    gap: 5px !important;
}

.subheading {
    font-size: 18px;
}

input[disabled] {
    background-color: #e0dfdf !important;
}

.addRow,
.deleteRow,
.editicon {
    background: none;
    border: none;
    cursor: pointer;
}

.text-wrap {
    text-wrap: auto !important;
}

