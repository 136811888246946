.satsangContainer {
    /* margin-top: 60px; */

    .formContainer {

        background-color: #fff;
        padding: 28px 40px;
        border-radius: 12px;
        margin: 22px 0;

        h3 {
            font-size: 20px;
        }

        .form-label {
            margin: 0;
            font-size: 14px;
            margin-bottom: 5px;
            color: #666666;
        }

        .divider {
            color: rgb(87, 89, 95);
            height: 1px;
            margin: 25px 0 25px 0;
        }

        h4 {
            font-size: 16px;
        }

        .delete-icon {
            color: #f40f0f;
        }

        .add-icon {
            margin-left: 10px;
        }

        .satsang-type-form {
            margin-top: 25px;
        }
    }
}