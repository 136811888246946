.unitsCardHeader{
display: flex;
align-items: center;
margin-bottom: 10px;
}
.unitsCardHeader button{
    width: 10rem;
}
table th{
    vertical-align: middle;
    text-align: center;
    background-color: #3a69ff !important;
    color: white !important;
}

td{
    vertical-align: middle;
    text-align: center;   
}