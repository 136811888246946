.fancy-background {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    max-width: 100%;
    margin-left: 11px !important;
}

.tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tab {
    color: black;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.tab:hover {
    background-color: #e0e4e4;
}

.tab.active {
    background-color: #007bb6;
    /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); */
    color: white;
}

.slideshow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.arrow {
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
    position: absolute;
    top: 50%;
    z-index: 100;
    /* Ensure the arrow stays above other elements */
}

.arrow.left {
    left: 15px;
}

.arrow.left i {
    left: 15px;
}

.arrow.right {
    right: 15px;
    z-index: 100;
}

.arrow:hover {
    background-color: #d9ecf7;
    transform: scale(1.1);
}

.arrow i {
    font-size: 18px;
    color: #007bb6;
}

.person-content {
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.charts-row {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: nowrap;
    overflow: hidden;
    max-width: 100%;
}

.fancy-chart {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(8px);
    border-radius: 12px;
    padding: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    flex: 1;
    min-width: 45%;
    max-width: 50%;
}

.fancy-chart:hover {
    transform: translateY(-5px);
}

.pie-container {
    position: relative;
    width: 100%;
    height: 250px;
}

.adhikari-name-role {
    text-align: center;
    margin-bottom: 20px;
}

.adhikari-name {
    font-size: 24px;
    font-weight: bold;
    color: #007bb6;
    /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); */
}

.adhikari-role {
    font-size: 18px;
    color: #555;
    font-style: italic;
}

/* Responsive Styling for Mobile View */
@media (max-width: 768px) {
    .charts-row {
        flex-wrap: wrap;
        /* Prevent wrapping */
        justify-content: center;
        gap: 10px;
        /* Reduce gap for smaller screens */
    }

    .fancy-chart {
        min-width: 90%;
        /* Keep side by side layout */
        max-width: 90%;
    }

    .tabs {
        flex-direction: row;
        gap: 10px;
        font-size: 13px !important;
    }

    .slideshow-container {
        justify-content: center;
    }

    .arrow {
        font-size: 24px !important;
        height: 25px !important;
        width: 25px !important;
    }

    .arrow.left {
        top: auto;
        bottom: 15px;
        left: 15px;
    }

    .arrow.right {
        top: auto;
        bottom: 15px;
        right: 15px;
    }

    .person-content {
        padding: 20px;
    }

    .pie-container {
        height: 200px;
        /* Adjust pie chart height for mobile */
    }

    /* Make the Present Count, Absent Count section more mobile-friendly */
    .color-indicator-box {
        flex-direction: column;
        align-items: center;
        /* Center-align on smaller screens */
    }

    .box-item {
        font-size: 14px;
        /* Smaller text for better mobile readability */
        margin-bottom: 5px;
        /* Add space between items */
    }

    /* Reduce size of text on smaller screens */
    .adhikari-name {
        font-size: 20px;
        /* Adjust name size for smaller screens */
    }

    .adhikari-role {
        font-size: 14px;
        /* Adjust role size for smaller screens */
    }
}

.color-indicator-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* Allows items to wrap for better spacing on smaller screens */
    margin-top: 10px;
}

.box-item {
    display: flex;
    align-items: center;
    margin: 0 5px;
    font-size: 14px;
    /* Set default font size */

}

.box-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 3px;
}