.accordion-button {
    height: 38px;
}

.accordion-body {
    border: 1px solid #dadeef;
    padding: 20px !important;
    border-radius: 6px;
}

.action-btn {
    width: 83px;
}

@media (max-width: 768px) {
.mblmargin {
    margin-top: 8px;
}
    .input-group {
        margin-top: 10px;
    }
}

.input-group-append {
    position: absolute;
    right: 10px;
    top: 6px;

}

.input-group-append:focus {
    outline: auto;
    padding-right: -1px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}