.table th,
.table td {
  min-width: 150px; /* Adjust this value for wider columns */
}

.scrollable-td {
  white-space: nowrap; /* Prevents wrapping */
  overflow-x: auto; /* Enables horizontal scrolling */
  max-width: 150px; /* Adjust the width as necessary */
}
.attendancePage .table .form-check {
  display: flex;
  justify-content: center;
}
.attendancePage .table .form-check .form-check-input {
  border: 1px solid #b2b2b2;
}

.attendancePage .table .form-check .form-check-input:checked {
  border: 1px solid #0d6efd;
}
.password-toggle-icon {
  position: absolute;
  right: 20px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #7f7b7b; /* Optional: Adjust the color */
}
